import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store, { history, persistor } from 'src/store';

import App from './App';

import 'src/assets/css/app.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';


if (document.getElementById('automation')) {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </PersistGate>
    </Provider>,

    document.getElementById('automation'),
  );
}
serviceWorker.unregister();
