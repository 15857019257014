import { Box, Card, Stack, styled, Typography } from '@mui/material';
import { Button } from '../../../../components/shared';
import images from '../../../../config/images';
import moment from 'moment';
import { dateFormat } from '../../../../config';
import { formatCurrency } from '../../../../utils/helper';
import React from 'react';

const StyledTitle = styled(Typography)`
  color: rgba(0, 0, 0, 0.6);
  font-size: 20px;
  font-weight: 600;
  line-height: 23.34px;
`;

const StyledPlanBaseName = styled(Typography)`
  color: #000000de;
  font-size: 20px;
  font-weight: 600;
  line-height: 23.34px;
`;

const StyledLicenceText = styled(Typography)`
  color: #000000de;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

const StyledSubscriptionEnds = styled(Typography)`
  color: #00000061;
  font-size: 12px;
  font-weight: 400;
  line-height: 19.92px;
  letter-spacing: 0.4px;
`;

const StyledSubscriptionCredits = styled(Typography)`
  color: #000000de;
  font-size: 28px;
  font-weight: 600;
  line-height: 32.676px;
`;

const StyledCreditsValue = styled(Typography)`
  color: #000000de;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
`;

const StyledCreditUsed = styled(Typography)`
  color: #00000099;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

export const SubscriptionCardSub = ({
  title,
  is14DaysTrial,
  handleChangeSubscription,
  summaryData,
  tenant,
  activePlanData,
  hasFreeOneMembershipAccess,
  allSubscriptions,
  toggleRechargeModal,
  loading,
  setShowLicenses,
  subscriptions,
}) => {
  const getSubscriptionDetails = () => {
    const subscriptionDetails = { monthlyPlan: {}, yearlyPlan: {} };
    const plans = (allSubscriptions || []).flatMap((subscription) => subscription.plans);

    if (activePlanData?.yearPlanKey || activePlanData?.year?.activePlanKey) {
      subscriptionDetails.yearlyPlan = plans.find(
        (plan) => plan?.planId === activePlanData?.year?.data?.item_price_id,
      );
    }

    if (activePlanData?.monthPlanKey || activePlanData?.month?.activePlanKey) {
      subscriptionDetails.monthlyPlan = plans.find(
        (plan) => plan?.planId === activePlanData?.month?.data?.item_price_id,
      );
    }

    return subscriptionDetails;
  };

  const { monthlyPlan, yearlyPlan } = getSubscriptionDetails();
  console.log(allSubscriptions, 'activePlanData------------->', activePlanData, {
    monthlyPlan,
    yearlyPlan,
  });

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          maxWidth: '860px',
          width: '100%',
          padding: '40px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '40px',
          borderRadius: '15px',
          background: '#FFF',
          boxShadow:
            '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Stack
            justifyContent={'space-between'}
            direction={'row'}
            width={'100%'}
            alignItems={'center'}
          >
            <StyledTitle>
              Subscription - {title == 'LMS' ? 'SuperReach Ai Learning' : title} Plan
            </StyledTitle>
            {(is14DaysTrial || hasFreeOneMembershipAccess) && (
              <Button color="secondary" onClick={() => handleChangeSubscription()}>
                <Typography
                  sx={{
                    color: '#1976D2',
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.4px',
                  }}
                >
                  Upgrade
                </Typography>
              </Button>
            )}
          </Stack>
          <Stack
            sx={{
              display: 'flex',
              padding: '24px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '24px',
              background: '#FAFAFA',
              alignSelf: 'stretch',
              borderRadius: '15px',
              marginTop: '24px',
            }}
          >
            {(activePlanData?.monthPlanKey || activePlanData?.month?.activePlanKey) && (
              <Stack
                gap={'24px'}
                direction={'row'}
                sx={{
                  width: '100%',
                }}
                alignItems={'center'}
              >
                <img src={images.app.SRArrowImg} alt={''} />
                <Stack
                  gap={'8px'}
                  sx={{
                    width: '100%',
                  }}
                >
                  <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <StyledPlanBaseName>
                      Basic monthly - ${monthlyPlan?.price || 0} pm
                    </StyledPlanBaseName>
                    <Button
                      sx={{
                        borderRadius: '4px',
                        background: '#1976D2',
                      }}
                      loading={loading ? loading === monthlyPlan.planId : false}
                      disabled={loading}
                      onClick={() => {
                        setShowLicenses({
                          planName: monthlyPlan?.planName,
                          monthlyPlan,
                          subscriptions: subscriptions?.subscription?.[monthlyPlan?.planKey],
                          open: true,
                        });
                        // initiatePayment(plan.planId, subscription, false);
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#FFF',
                          fontSize: '14px',
                          fontWeight: 500,
                          lineHeight: '24px',
                          letterSpacing: '0.4px',
                        }}
                      >
                        Add licenses
                      </Typography>
                    </Button>
                  </Stack>
                  <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <StyledLicenceText>
                      {activePlanData?.month?.data?.quantity} licenses assigned
                    </StyledLicenceText>
                    <StyledSubscriptionEnds>
                      Subscription ends:{' '}
                      {moment.unix(activePlanData?.month?.data?.next_billing_at).format(dateFormat)}
                    </StyledSubscriptionEnds>
                  </Stack>
                </Stack>
              </Stack>
            )}
            {!is14DaysTrial &&
              (activePlanData?.yearPlanKey || activePlanData?.year?.activePlanKey) && (
                <Stack
                  gap={'24px'}
                  direction={'row'}
                  sx={{
                    width: '100%',
                  }}
                  alignItems={'center'}
                >
                  <img src={images.app.SRArrowImg} alt={''} />
                  <Stack
                    gap={'8px'}
                    sx={{
                      width: '100%',
                    }}
                  >
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                      <StyledPlanBaseName>
                        Basic annual - ${yearlyPlan?.price || 0} py
                      </StyledPlanBaseName>
                      <Button
                        sx={{
                          borderRadius: '4px',
                          background: '#1976D2',
                        }}
                        loading={loading ? loading === yearlyPlan?.planId : false}
                        disabled={loading}
                        onClick={() => {
                          setShowLicenses({
                            planName: yearlyPlan?.planName,
                            monthlyPlan,
                            subscriptions: subscriptions?.subscription?.[yearlyPlan?.planKey],
                            open: true,
                          });
                          // initiatePayment(plan.planId, subscription, false);
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#FFF',
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '24px',
                            letterSpacing: '0.4px',
                          }}
                        >
                          Add licenses
                        </Typography>
                      </Button>
                    </Stack>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                      <StyledLicenceText>
                        {activePlanData?.year?.data?.quantity} licenses assigned
                      </StyledLicenceText>
                      <StyledSubscriptionEnds>
                        Subscription ends:{' '}
                        {moment
                          .unix(activePlanData?.year?.data?.next_billing_at)
                          .format(dateFormat)}
                      </StyledSubscriptionEnds>
                    </Stack>
                  </Stack>
                </Stack>
              )}
          </Stack>
        </Box>
      </Card>

      <Card
        sx={{
          marginTop: '37px',
          display: 'flex',
          maxWidth: '860px',
          width: '100%',
          padding: '40px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '40px',
          borderRadius: '15px',
          background: '#FFF',
          boxShadow:
            '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Stack
            justifyContent={'space-between'}
            direction={'row'}
            width={'100%'}
            alignItems={'center'}
          >
            <StyledTitle>Credits</StyledTitle>
            <Button
              color="secondary"
              onClick={() =>
                window.open(
                  title?.toLowerCase() === 'lms'
                    ? 'https://superreach.com/training/'
                    : 'https://superreach.com/outreach/',
                  '_blank',
                )
              }
            >
              <Typography
                sx={{
                  color: '#1976D2',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '0.4px',
                }}
              >
                Find out more
              </Typography>
            </Button>
          </Stack>
          <Box
            sx={{
              display: 'flex',
              padding: '24px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              background: '#FAFAFA',
              alignSelf: 'stretch',
              borderRadius: '6px',
              marginTop: '24px',
            }}
          >
            <Stack
              gap={'24px'}
              sx={{
                width: '100%',
              }}
            >
              <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <StyledPlanBaseName sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  Credits available <img src={images.icons.questionRoundIcon} alt={''} />
                </StyledPlanBaseName>
                <StyledSubscriptionCredits>
                  ${formatCurrency(tenant?.credits)}
                </StyledSubscriptionCredits>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <StyledCreditUsed>
                  Credits used last 30 days :{' '}
                  <StyledCreditsValue component={'span'}>
                    ${formatCurrency(summaryData?.creditsUsed)}
                  </StyledCreditsValue>
                </StyledCreditUsed>
                <Button
                  sx={{
                    borderRadius: '4px',
                    background: '#1976D2',
                  }}
                  onClick={() => {
                    toggleRechargeModal('data');
                  }}
                >
                  <Typography
                    sx={{
                      color: '#FFF',
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '24px',
                      letterSpacing: '0.4px',
                    }}
                  >
                    Add credits
                  </Typography>
                </Button>
              </Stack>
            </Stack>
          </Box>

          <Box
            sx={{
              display: 'flex',
              padding: '24px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              background: '#FAFAFA',
              alignSelf: 'stretch',
              borderRadius: '6px',
              marginTop: '24px',
            }}
          >
            <Stack
              gap={'24px'}
              sx={{
                width: '100%',
              }}
            >
              <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <StyledPlanBaseName sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  Video minutes available <img src={images.icons.questionRoundIcon} alt={''} />
                </StyledPlanBaseName>
                <StyledSubscriptionCredits>
                  {Math.round(tenant?.roleplayMinutes ?? 0)} mins
                </StyledSubscriptionCredits>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <StyledCreditUsed>
                  Minutes used last 30 days :{' '}
                  <StyledCreditsValue component={'span'}>
                    {tenant?.roleplayMinutesUsedThisMonth > 0
                      ? Math.round(tenant?.roleplayMinutesUsedThisMonth)
                      : 0}{' '}
                    mins
                  </StyledCreditsValue>
                </StyledCreditUsed>
                <Button
                  sx={{
                    borderRadius: '4px',
                    background: '#1976D2',
                  }}
                  onClick={() => {
                    toggleRechargeModal('roleplay');
                  }}
                >
                  <Typography
                    sx={{
                      color: '#FFF',
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '24px',
                      letterSpacing: '0.4px',
                    }}
                  >
                    Add credits
                  </Typography>
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default SubscriptionCardSub;
